import React from 'react';
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Helmet} from "react-helmet";


const AvisoLegal = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Seo title="Contact us" />
    <article className="global-wrapper blog-post" itemScope itemType="http://schema.org/Article">
    <h1 className="entry-title">Condiciones Generales de uso del sitio web {siteTitle}</h1>
        <section itemProp="articleBody">
                <p>El presente aviso legal regula el uso del servicio del portal de Internet <Link to="/">{siteTitle}</Link>, en adelante EL SITIO.<br />El uso del sitio Web EL SITIO se rige por las normas establecidas
                    en el presente Aviso Legal. Con su utilización, usted, de ahora en adelante el USUARIO acepta las condiciones de uso.</p>
                <h3>PRIMERA. – CONDICIONES DE ACCESO Y USO</h3>
                <p>El USUARIO queda informado, y acepta, que el acceso a EL SITIO no supone, en modo alguno, el inicio de una relación comercial o de otro tipo.</p>
                <p>La utilización del sitio web EL SITIO no conlleva la obligatoriedad de inscripción del USUARIO. Las condiciones de acceso y uso del presente sitio web se rigen estrictamente por la legalidad vigente y por el principio de buena fe comprometiéndose
                    el USUARIO a realizar un buen uso de la web. Quedan prohibidos todos los actos que vulneren la legalidad, derechos o intereses de terceros: derecho a la intimidad, protección de datos, propiedad intelectual etc.</p>
                <p>Expresamente EL SITIO prohíbe los siguientes:</p>
                <p>Realizar acciones que puedan producir mediante el sitio web o a través del mismo, cualquier tipo de daño a los sistemas de EL SITIO o a terceros.</p>
                <p>Realizar sin la debida autorización cualquier tipo de publicidad o información comercial directamente o de forma encubierta, el envío de correos masivos (“spaming”) o envío de grandes mensajes con el fin de bloquear servidores de la red (“mail bombing”).</p>
                    <p>EL SITIO podrá interrumpir en cualquier momento el acceso a su sitio web si detecta un uso contrario a la legalidad, la buena fe o a las presentes condiciones generales. Ver cláusula sexta.</p>
                    <h3>SEGUNDA. – CONTENIDOS.</h3>
                    <p>Los contenidos incorporados en este sitio web han sido elaborados e incluidos por fuentes internas y externas. EL SITIO únicamente se hace responsable por los contenidos elaborados de forma interna.</p>
                    <p>Queda reservado el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su página Web, pudiendo cambiar, suprimir o añadir tanto los contenidos y productos que se presten a través de la misma como la forma en la que
                        éstos aparezcan presentados o localizados en sus servidores.</p>
                    <h3>TERCERA. – DERECHOS DE AUTOR Y MARCA</h3>
                    <p>Todos los contenidos del sitio Web EL SITIO (incluyendo, sin carácter limitativo, bases de datos, imágenes y fotografías, dibujos, gráficos y archivos de texto) son propiedad de EL SITIO o de los proveedores de contenidos y terceras
                        partes debidamente indicadas y están protegidos por las normas nacionales o internacionales de propiedad intelectual. La recopilación, diseño, ordenación y montaje de todo el contenido del sitio Web es propiedad exclusiva de EL SITIO
                        o de los proveedores de contenidos y terceras partes debidamente indicadas y se encuentra protegida por las normas nacionales e internacionales de propiedad industrial e intelectual.</p>
                    <p>EL SITIO utiliza fuentes externas para la elaboración de sus contenidos en determinadas ocasiones y también establece enlaces a artículos o informaciones de terceros citando siempre la fuente. El legítimo titular de los derechos de autor
                        de estas informaciones así incluidas podrá solicitar en cualquier momento la eliminación de las referidas referencias.</p>
                    <p>Las marcas, rótulos, signos distintivos o logos del Web referenciado son titularidad de EL SITIO o de los proveedores de contenidos y terceras partes debidamente indicadas y están debidamente registrados, así como los textos, datos y dibujos
                        gráficos; o bien son titularidad de las entidades proveedoras de información, no pudiendo ser objeto de ulterior modificación, copia, transformación, alteración, reproducción, adaptación o traducción por parte de terceros, sin la expresa autorización
                        por parte del titular de dicho contenido. La puesta a disposición de los textos, datos y dibujos gráficos no implica, en ningún caso, la cesión de su titularidad o la concesión de un derecho de explotación, reproducción, difusión, transformación,
                        distribución, o transmisión a su favor, distinto del derecho de uso que comporta la utilización legítima del Web.</p>
                    <h3>CUARTA. – JURISDICCIÓN Y LEY APLICABLE</h3>
                    <p>Las presentes condiciones generales se rigen por la legislación española. Son competentes para resolver toda controversia o conflicto que se derive de las presentes condiciones generales los Juzgados de BARCELONA renunciando expresamente el USUARIO
                        a cualquier otro fuero que pudiera corresponderle.</p>
                    <p>EL SITIO se compromete al cumplimiento de su obligación de secreto de los datos de carácter personal y de su deber de guardarlos de forma confidencial y adoptará las medidas necesarias para evitar su alteración, pérdida, tratamiento o
                        acceso no autorizado, habida cuenta en todo momento del estado de la tecnología.</p>
                    <p>Asimismo, se informa al usuario de su derecho de acceso, rectificación, cancelación y, en su caso, oposición de acuerdo con lo establecido en la ley orgánica 15/1999, de 13 de diciembre, de protección de datos de carácter personal y demás normativa
                        aplicable al efecto, los cuales podrá ejercitar mediante comunicación remitida a la sección <Link to="/contacto/">Contacto</Link>, con asunto “LOPD: Atención derechos de usuario”.</p>
                    <h3>QUINTA. – LIMITACIÓN DE RESPONSABILIDAD</h3>
                    <p>EL SITIO no ofrece garantías de ninguna clase en cuanto al funcionamiento del presente Web o a la información contenida en el mismo, ni será responsable de los daños o perjuicios, de cualquier índole, que puedan derivarse de su uso.</p>
                    <p>Asimismo, EL SITIO excluye toda responsabilidad por la licitud, contenido y calidad de los datos e informaciones ofrecidos por terceras entidades a través del citado sitio Web.</p>
                    <h3>SEXTA. – VALIDEZ</h3>
                    <p>En caso de que cualquier cláusula del presente documento sea declarada nula, las demás cláusulas seguirán vigentes y se interpretarán teniendo en cuenta la voluntad de las partes y la finalidad misma de las presentes condiciones. EL SITIO
                        podrá no ejercitar alguno de los derechos y facultades conferidos en este documento lo que no implicará en ningún caso la renuncia a los mismos salvo reconocimiento expreso por parte de EL SITIO.</p>
                    <h3>SEPTIMA. – PRODUCTOS DE AFILIACIÓN</h3>
                    <p>EL SITIO participa en el Programa de Afiliados de Amazon EU, un programa de publicidad para afiliados diseñado para ofrecer a sitios web un modo de obtener comisiones por publicidad, publicitando e incluyendo enlaces a Amazon.co.uk/ Amazon.de/
                        de.buyvip.com/Amazon.fr/Amazon.it/it.buyvip.com/ Amazon.es/es.buyvip.com.</p>
                    <p>Los productos analizados por nosotros y que aparecen en esta página web pertenecen a AMAZON SERVICES LLC. El contenido se muestra directamente de la plataforma de Amazon.es, por lo que si Amazon lo cambia sin previo aviso, puede cambiar el contenido
                        y los precios aquí expuestos!</p>
        </section>
    </article>
    </Layout>
  )
}

export default AvisoLegal

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
